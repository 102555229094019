.img {
  width: 500;
  height: auto;
  object-fit: cover;
}

hr {
  border-top: 1px solid grey;
  border-bottom: none;
  border-left: none;
  border-right: none;
  border-width: 20px;
}
